<template>
    <div>
        <div class="banner">
            <img src="~@/assets/images/fangan_banner.png" alt="" @load="handleImgeLoad(0)">
            <div class="sub_banner">
                <div class="inner">
                    <div v-for="(item,index) in navList" :key="item.id" @click="handleNavTabs(index,item)" :class="['nav_item',navActive===index?'nav_active':'']">{{ item.title }}</div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="shuzi" ref="page1">
                <div class="inner">
                    <Scheme class="shuzi_title">
                        <template v-slot:title>杰文数字</template>
                        <template v-slot:subTitle>数字教育创新提供解决方案</template>
                    </Scheme>
                    <div class="shuzicontent">
                        <img src="~@/assets/images/shuzi_tushi.png" alt="">
                    </div>
                </div>
                
            </div>
            <div class="shuzi zhineng" ref="page2">
                <div class="inner">
                    <Scheme class="zhineng_title">
                        <template v-slot:title>杰文智能</template>
                        <template v-slot:subTitle>提供全方位的智慧图书馆、智慧校园、智慧场馆等整体解决方案，集设计、采购、实施、交付、运营等一体化全流程服务</template>
                    </Scheme>
                    <div class="zhinengcontent">
                        <div class="img_box">
                            <img src="~@/assets/images/zhineng_zuotu.png" alt="">
                        </div>
                        <div class="content_box">
                            <div class="item">
                                <div class="item_inner">
                                    <div class="item_text">
                                        <h2>方案概述</h2>
                                        <p>智能业务是杰文信息重要业务板块之一，致力于为图书馆、普教高校，政企单位，物流仓储等，提供全方位的智慧图书馆、智慧校园、智慧场馆等整体解决方案，集设计、采购、实施、交付、运营等一体化全流程服务</p>
                                    </div>
                                    <div class="img_box" style="padding-top: 38px;">
                                        <img src="~@/assets/images/zhineng_youtu.png" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="item_inner">
                                    <div class="item_text">
                                        <h2>方案优势</h2>
                                        <p>提供软硬一体化的整体智能解决方案，打造智慧、高效、便捷的智能解决方案，构建智能+N的生态环境。利用智能+N的系统解决方案，完成智能化的管理手段，从而满足教育、政、企高效管理的需求，降低成本、高质高效。旨在为客户打造使用合理高效、配置智能环境系统与先进信息管理系统以及大数据分析系统，使用新技术、新材料、新产品，为客户提供优质、安全、绿色、环保的智能化解决方案。</p>
                                    </div>
                                    <div class="img_box">
                                        <img src="~@/assets/images/zhineng_youtu_er.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="shuzi fangzhen" ref="page3">
                <div class="inner">
                    <Scheme class="zhineng_title">
                        <template v-slot:title>杰文仿真</template>
                        <template v-slot:subTitle>运用虚拟现实技术、计算机技术、信息技术、人机交互技术等建立虚拟仿真实训系统</template>
                    </Scheme>
                    <p class="jianjie">运用虚拟现实技术、计算机技术、信息技术、人机交互技术等建立虚拟仿真实训系统，以职业教育、高等教育常见专业学科教学需求为基础，定制开发教学课件内容为载体，“逼真”展示流程、模拟工艺、人机交互等操作，让用户获得生动直观的感性认知，增进对学科的理解，实现理论到实践的平滑过渡，为职教、高教各学科建设提供全面创新的虚拟仿真教学整体解决方案。</p>
                    <div class="fangzhencontent">
                        <div class="fangzhennav">
                            <div :class="['fangzhen_item',fangzhenactive==index?'fangzhenactive':'']" v-for="(item,index) in fangzhenList" :key="index" @click="handleFengzhenNav(item,index)">{{ item.title }}</div>
                        </div>
                        <div class="fangzhenright">
                            <div class="img_box">
                                <img :src="fangzhenObj.cover" alt="">
                            </div>
                            <div class="essay">
                                <div class="title">
                                    <span>方案优势</span>
                                </div>
                                <div class="text">
                                    {{ fangzhenObj.syno }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="shuzi ruanjian" ref="page4">
                <div class="inner">
                    <Scheme class="zhineng_title">
                        <template v-slot:title>杰文软件</template>
                        <template v-slot:subTitle>全面提升并运用线上、线下教学能力的混合式教学模式，构成院校智能化教学体系</template>
                    </Scheme>
                    <p class="text">
                        杰文软件基于高职院校智慧教务与资源库平台服务架构进行建设，打造课程学习、知识传播与管理分享于一体的一站式系统平台，杰文软件在功能上集合软件平台开发+可视化的应用场景进行高职院校智慧化概念构建，助力职业教育智慧化资源转型。构建课程上线、在线学习、互动评价的全过程闭环软件平台，融合线上线下、跨平台、跨场景的交叉运用。全面提升并运用线上、线下教学能力的混合式教学模式，构成院校智能化教学体系
                    </p>
                    <img src="~@/assets/images/ruanjian_tuyi.png" @load="handleImgeLoad" alt="">
                    <div class="tone">
                        <div class="tone_item">
                            <div class="title">特色服务</div>
                            <div class="tone_text">具有学校特色的个性化、多任务、多场景的数字化混合式教学平台特色，基于学校教务和内容痛点，符合院校使用习惯与特色，按需构成混合式的教学模式，实现教务基础应用</div>
                        </div>
                        <div class="tone_item">
                            <div class="title">内容健全</div>
                            <div class="tone_text">汇聚校本资源库、师生档案、专家资源库、评估评测体系，确保学校特色课程和资源的存储展示，便于学生学习，教师评估</div>
                        </div>
                        <div class="tone_item">
                            <div class="title">传播平台全覆盖</div>
                            <div class="tone_text">将信息数据通过图谱给予展示，实现全面、动态、连续的教学数据采集，为学科建设。质量评估、数据治理提供支撑</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="shuzi xueyuan" ref="page5">
                <div class="inner">
                    <Scheme class="zhineng_title">
                        <template v-slot:title>杰文学院</template>
                        <template v-slot:subTitle>全面提升并运用线上、线下教学能力的混合式教学模式，构成院校智能化教学体系</template>
                    </Scheme>
                    <p class="text">杰文学院坚持“以赛促研、以赛促教、以赛促学”的根本目标深入教育创新核心层，助力教学体系新生态的构建与实施，纵深推动高职教育的高质量建设、教师的高质量发展、人才的高质量培养，深化人才培养体系，始终在"专业共建、课程共研、实践创新、人才共育"砥砺前行。</p>
                    <img src="~@/assets/images/xueyuan_tushi.png" @load="handleImgeLoad" alt="">
                </div>

            </div>
        </div>
        <contactus></contactus>
    </div>
</template>

<script>
import Scheme from '@/components/Scheme'
import contactus from "../components/ComeInJW/ContactUs.vue";
import {scrollTo} from '@/utils/scroll-to'
export default {
    name:'Solution',
    components:{Scheme,contactus},
    data(){
        return {
            navList:[
                {
                    title:'杰文数字',
                    id:0
                },{
                    title:'杰文智能',
                    id:1
                },{
                    title:'杰文仿真',
                    id:2
                },{
                    title:'杰文软件',
                    id:3
                },{
                    title:'杰文学院',
                    id:4
                },
            ],
            navActive:-1,
            fangzhenList:[
                {
                    title:'河南交通公路虚拟仿真实训川申报项目',
                    cover:require('@/assets/images/2023_03_21_yumi_banner/gonglu_banner_liu.png'),
                    syno:'操作简便、直观、灵活，提升学生习探索兴趣，激发教师创新教学热情；理论知识、实践应用充分交汇融合，有效提升了实验教学质量；内容多源化、过程自主化、能力评价科学化、教学辅助智能化；软件成熟度高，质量及售后口碑良好，大量成功交付案例'
                },{
                    title:'古建筑非遗传承和玺彩画实训系统',
                    cover:require('@/assets/images/2023_03_21_yumi_banner/caihua_banner_yi.png'),
                    syno:'操作简便、直观、灵活，提升学生习探索兴趣，激发教师创新教学热情；理论知识、实践应用充分交汇融合，有效提升了实验教学质量；内容多源化、过程自主化、能力评价科学化、教学辅助智能化；软件成熟度高，质量及售后口碑良好，大量成功交付案例'
                },{
                    title:'智慧物流虚拟仿真实训系统',
                    cover:require('@/assets/images/2023_03_21_yumi_banner/wuliu_banner_san.jpg'),
                    syno:'操作简便、直观、灵活，提升学生习探索兴趣，激发教师创新教学热情；理论知识、实践应用充分交汇融合，有效提升了实验教学质量；内容多源化、过程自主化、能力评价科学化、教学辅助智能化；软件成熟度高，质量及售后口碑良好，大量成功交付案例'
                },{
                    title:'食品安全-新冠病毒检查VR',
                    cover:require('@/assets/images/2023_03_21_yumi_banner/shipin_banner_san.png'),
                    syno:'操作简便、直观、灵活，提升学生习探索兴趣，激发教师创新教学热情；理论知识、实践应用充分交汇融合，有效提升了实验教学质量；内容多源化、过程自主化、能力评价科学化、教学辅助智能化；软件成熟度高，质量及售后口碑良好，大量成功交付案例'
                },{
                    title:'玉米种子生产与加工仿真实训系统',
                    cover:require('@/assets/images/2023_03_21_yumi_banner/yumi_banner.png'),
                    syno:'操作简便、直观、灵活，提升学生习探索兴趣，激发教师创新教学热情；理论知识、实践应用充分交汇融合，有效提升了实验教学质量；内容多源化、过程自主化、能力评价科学化、教学辅助智能化；软件成熟度高，质量及售后口碑良好，大量成功交付案例'
                },
            ],
            fangzhenactive:0,
            fangzhenObj:{
                title:'河南交通公路虚拟仿真实训川申报项目',
                cover:require('@/assets/images/2023_03_21_yumi_banner/gonglu_banner_liu.png'),
                syno:'操作简便、直观、灵活，提升学生习探索兴趣，激发教师创新教学热情；理论知识、实践应用充分交汇融合，有效提升了实验教学质量；内容多源化、过程自主化、能力评价科学化、教学辅助智能化；软件成熟度高，质量及售后口碑良好，大量成功交付案例'
            },
            modulePosition:{
                module1:0,
                module2:0,
                module3:0,
                module4:0,
                module5:0,
            }
        }
    },
    mounted(){
        window.addEventListener('scroll',this.handleImgeLoad)
    },
    destroyed(){
        window.removeEventListener('scroll',this.handleImgeLoad)
    },
    methods:{
        handleImgeLoad(i){
            this.modulePosition.module1 = this.$refs.page1.offsetTop
            this.modulePosition.module2 = this.$refs.page2.offsetTop
            this.modulePosition.module3 = this.$refs.page3.offsetTop
            this.modulePosition.module4 = this.$refs.page4.offsetTop
            this.modulePosition.module5 = this.$refs.page5.offsetTop
            let scrollTop = (document.documentElement.scrollTop + 90)
            if(scrollTop>=this.modulePosition.module1&&scrollTop<this.modulePosition.module2){
                this.navActive = 0
            }else if(scrollTop>=this.modulePosition.module2&&scrollTop<this.modulePosition.module3){
                this.navActive = 1
            }else if(scrollTop>=this.modulePosition.module3&&scrollTop<this.modulePosition.module4){
                this.navActive = 3
            }else if(scrollTop>=this.modulePosition.module4&&scrollTop<this.modulePosition.module5){
                this.navActive = 4
            }else{
                this.navActive = -1
            }
            if(i===0&&this.$route.query.id){
                this.handleNavTabs(this.$route.query.id*1)
            }
        },
        handleNavTabs(i,data){
            this.navActive = i
            scrollTo((this.$refs['page'+ (i + 1)]).offsetTop - 80,400)
        },
        handleFengzhenNav(data,i){
            this.fangzhenactive = i
            this.fangzhenObj = data
        }
    }
}
</script>

<style lang="scss" scoped>
.banner{
    position: relative;
    .sub_banner{
        margin-top: -74px;
        .inner{
            display: flex;
            justify-content: space-around;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #FFFFFF;
            .nav_item{
                padding: 0 12px;
                padding-bottom: 43px;
                position: relative;
                cursor: pointer;
                transition: all .3s;
                &:hover{
                    color: #005FC6;
                }
                &.nav_active{
                    &::after{
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 5px;
                        border-radius: 5px;
                        background-color: #005FC6;
                    }
                }
            }
        }
    }
}
.shuzi{
    background: url('~@/assets/images/shuzi_banner.png');
    background-size: cover;
    padding: 73px 0;
    .shuzi_title{
        margin-bottom: 61px;
    }
}
.zhineng{
    background: url('~@/assets/images/zhineng_bg.png');
    .img_box{
        width: 603px;
        margin-right: 19px;
    }
    .zhinengcontent{
        display:flex;
        align-items: center;
        margin-top: 60px;
    }
    .content_box{
        flex: 1;
        // display: flex;
        .item{
            min-height: 370px;
            width: 100%;
            margin-bottom: 40px;
            border-radius: 8px;
            padding: 5px;
            background-image: linear-gradient(0deg,#ffffff3d, #FFFFFF);
            overflow: hidden;
            &:last-of-type{
                margin-bottom: 0;
            }
            .item_inner{
                height: 100%;
                background-image: linear-gradient(0deg,#FDFEFF, #F3F6F9);
                min-height: 360px;
                padding: 5px;
                border-radius: 4px;
                padding: 39px 40px 0;
                display: flex;
                justify-content: space-between;
                .item_text{
                    flex: 1;
                    padding-right: 30px;
                    h2{
                        font-size: 24px;
                        font-family: Source Han Sans CN;
                        color: #333333;
                        margin-bottom: 10px;
                    }
                    p{
                        font-family: Source Han Sans CN;
                        color: #98A3B7;
                        line-height: 1.8;
                    }
                }
                .img_box{
                    width: 110px;
                    margin-right: 0;
                }
            }
        }
    }
}
.fangzhen{
    background: #F5F7FB;
    .jianjie{
        font-size: 20px;
        color: #5D6676;
        text-align: center;
        margin-top: 42px;
        margin-bottom: 57px;
        line-height: 48px;
    }
    .fangzhencontent{
        display: flex;
        .fangzhennav{
            width: 355px;
            border-top: 5px solid #fff;
            border-bottom: 5px solid #fff;
            background-color: #F5F7FA;
            .fangzhen_item{
                cursor: pointer;
                padding: 30px 20px;
                border-left: 5px solid #fff;
                border-right: 5px solid #fff;
                position: relative;
                font-size: 17px;
                &.fangzhenactive{
                    color: #005FC6;
                    &::after{
                        content: '';
                        position: absolute;
                        left: -5px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 6px;
                        height: 48px;
                        background: #DD5839;
                    }
                }
                
            }
        }
        .fangzhenright{
            flex: 1;
            background: url('~@/assets/images/fangzhen_kuang_bg.png');
            background-size: cover;
            background-position: center center;
            display: flex;
            padding: 23px;
            .essay{
                width: 230px;
                margin-left: 25px;
                .title{
                    color: #005FC6;
                    font-size: 24px;
                    margin-bottom: 15px;
                    span{
                        display: inline-block;
                        padding-bottom: 15px;
                        border-bottom: 2px solid #005FC6;
                    }
                }
                .text{
                    line-height: 2;
                }
            }
            .img_box{
                padding-top: 25px;
                flex: 1;
            }
        }
    }
}
.ruanjian{
    background: url('~@/assets/images/ruanjian_bg.png');
    .text{
        font-size: 20px;
        color: #5D6676;
        line-height: 2;
        text-align: center;
        margin-bottom: 70px;
        margin-top: 53px;
    }
    .tone{
        display: flex;
        justify-content: space-between;
        .tone_item{
            margin-top: 66px;
            width: 354px;
            height: 399px;
            background: url('~@/assets/images/tuanjian_tese.png');
            background-size: 100% 100%;
            padding: 78px 45px 0;
            &:nth-of-type(2){
                background: url('~@/assets/images/ruanjian_neirong.png');
            }
            &:nth-of-type(3){
                background: url('~@/assets/images/ruanjian_chuanbo.png');
            }
            .title{
                font-size: 24px;
                font-weight: bold;
                color: #282828;
                margin-bottom: 20px;
            }
            .tone_text{
                font-size: 18px;
                color: #5D6676;
                line-height: 2;
            }
        }
    }
}
.xueyuan{
    background: url('~@/assets/images/xueyuan_banner.png');
    .text{
        font-size: 20px;
        color: #5D6676;
        line-height: 2;
        text-align: center;
        margin-bottom: 70px;
        margin-top: 53px;
    }
}
</style>